<template>
  <div class="page-coantainer">
    <el-dialog
      title="编辑用户"
      :visible.sync="editData.isOpen"
      width="40%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
          <el-form-item label="用户名" prop="username">
            <el-input v-model.trim="formData.username"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input type="number" v-model.trim="formData.phone"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select v-model="formData.sex" placeholder="请选择性别">
                <span v-for="(item, index) in sex" :key="index">
                    <el-option :label="item.name" :value="Number(item.type)" :key="index"></el-option>
               </span>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { editUser, getUserInfoById } from '@/api/login'

export default {
  props: {
    editData: {
      type: Object
    }
  },
  data () {
    // 验证手机号的规则
    const checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法手机号'))
    }
    return {
      formData: {
        username: '',
        password: '',
        phone: '',
        sex: ''
      },
      sex: [{
        type: 1,
        name: '男'
      }, {
        type: 2,
        name: '女'
      }],
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请填写手机号码', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    'editData.isOpen' () {
      this.getUserInfoById()
    }
  },
  methods: {
    /* 获取当前用户信息 */
    getUserInfoById () {
      getUserInfoById(this.editData.id).then(res => {
        this.formData = res.data
      })
    },
    /* 更新用户 */
    editUser () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          editUser(this.formData).then(res => {
            this.$emit('closeDialog', { isOpen: false, type: 'success' })
            this.$refs.form.resetFields()
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          })
        }
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-coantainer {
  ::v-deep .el-dialog {
    &__body {
      max-height: 500px;
      overflow: auto;
    }
  }
}
</style>
